<template>
    <div style="margin: -15px -15px;background-color: #f0f2f5;">
      <!-- tag查询框 -->
      <div class="datePicker">
        操作日期：
        <a-range-picker
         v-model="timeVal"
         @change="onChange_range_picker " 
         style="width:300px"
         />
         <!-- @click="searchBtn" -->
         <a-button type="primary" style="margin-left: 30px;" >查询</a-button>
         <a-button style="position: absolute;right:20px" @click="expotExcel()"> <a-icon type="file-excel" style="color:green" /> 批量导出</a-button>
      </div>
      <div style="padding:20px;background-color: #fff;">
        <a-table :columns="columns" style="min-height: 500px;background-color: #fff;" :data-source="HeplTable" :rowKey="record => record.id"   :pagination="false">
           <span slot="operate" slot-scope="text,item,index">
             <span v-if="text =='education'">进修</span>
             <span v-if="text =='recruit'">招聘</span>
             <span v-if="text =='train'">培训</span>
           </span>
        </a-table>
        <!-- table框的分页 -->
        <div style="margin-top: 28px;display:flex;align-items:center;justify-content:space-between">
          <span
         style="font-size: medium;color: #929292;margin-left: 3%;font-size: 14px;  bottom: 3%;">
         共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} / {{ Math.ceil(pagination.total /
           pagination.pageSize)
         }} 页
          </span>
          <a-pagination style="float: right;" show-quick-jumper show-size-changer :page-size.sync="pagination.pageSize"
          :total=pagination.total v-model="pagination.current"  @change="pageChange" @showSizeChange="pageChange"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {getcollection} from "@/service/DrugQueryManagement";
  import { getShareRecord,getShareExport} from "@/service/Recruiting";
  export default {
   data() {
      return{
        timeVal:[],
        pagination: {   //存储大table的分页
          total: 0,
          current: 1,
          pageSize: 10,
          pageNum: 1,
        },
        columns:[
           {
             title: '姓名',
             dataIndex: 'name',
             key: 'name',
             align:'center',
             // width:'8%',
           },
           {
             title: '医院',
             dataIndex: 'hospitalName',
             key: 'hospitalName',
             align:'center',
           },
           {
             title: '科室',
             dataIndex: 'hospitalOffices',
             key: 'hospitalOffices',
             align:'center',
             // width:'8%',
           },
           {
             title: '职称',
             dataIndex: 'jobTitle',
             key: 'jobTitle',
             align:'center',
           },
           {
             title: '分享模块',
             align:'center',  
             dataIndex: 'type',
             key: 'type',
             scopedSlots: { customRender: 'operate' },
           },
           {
             title: '分享内容',
             align:'center',  
             dataIndex: 'title',  //没返回
             key: 'title',
           },
           {
             title: '操作时间',
             width: '20%',
             dataIndex: 'createdTime',
             align:'center',  
             key: 'createdTime',
           },
         ],
         HeplTable:[],
         StartTime:"",
         EndTime:"",
      }
   },
    
   created() {
       this.getTable()
     },
   methods:{
      // 日期
      onChange_range_picker(date, dateString){
        this.StartTime = dateString[0]
        this.EndTime = dateString[1]
        this.getTable()
      },
      // 分页
      pageChange(page_no, page_size) {
        this.pagination.pageNum = page_no
        this.pagination.pageSize = page_size
        this.getTable()
      },
      // 获取列表
      async getTable(){
        const response = await getShareRecord(this.StartTime,this.EndTime,this.pagination.pageNum,this.pagination.pageSize)
        if (response.code === 0) {
          console.log("请求",response)
          this.HeplTable = response.data
          this.pagination.total = response.count
        } else{
          this.$message.warning(response.message)
        }
      },
      // 导出
      async expotExcel(){
        const data = {
          startTime:this.StartTime,
          endTime:this.EndTime,
          ExelName:"进修招聘分享记录"
        }
        const response = await getShareExport(data)
      },
   }
  }
  </script>
  
  <style lang="scss" scoped>
   .datePicker{
     background-color: #fff;
     width: 100%;
     height: 64px;
     padding: 20px;
     display: flex;
     align-items: center;
     margin-bottom: 10px;
     position: relative;
  }
  ::v-deep .ant-table-thead>tr>th {
		font-weight: bold;
		
	}
  </style>